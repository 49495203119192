// add bootstrap variable settings here

@import 'bootstrap/bootstrap';

@font-face{
  font-family: 'Roboto-Regular';
  src:url('/fonts/Roboto-Regular.ttf');
}

// add more css stuff here

@font-face{
  font-family: 'Roboto-Regular';
  src:url('/fonts/Roboto-Regular.ttf');
}
@font-face{
  font-family: 'Roboto-Bold';
  src:url('/fonts/Roboto-Bold.ttf');
}
@font-face{
  font-family: 'Roboto-Light';
  src:url('/fonts/Roboto-Light.ttf');
}
@font-face{
  font-family: 'RobotoMono-Regular';
  src:url('/fonts/RobotoMono-Regular.ttf');
}
@font-face{
  font-family: 'Roboto-Medium';
  src:url('/fonts/Roboto-Medium.ttf');
}



$font-face-regular:'Roboto-Regular';
$font-face-bold:'Roboto-Bold';
$font-face-light:'Roboto-Light';
$font-face-medium:'Roboto-Medium';

$orange:#FFB647;
$orangehover:#F09C1A;
$green:#18F2B2;
$sky-blue:#26D3FF;
$sky-blue-hover:#00BEEB;
$blue-purple:#B2ABF2;
$slate-gray:#526881;
$light-gray:#748599;
$flash-white:#EDF0F4;
$lighter-gray:#F7FAFF;
$black:#000;
$white:#fff;
$bluish:#DBE8FD;

/*overriding bootstrap variables */
$primary: $sky-blue !default;
$secondary: $orange !default;

/*fonts variables*/
$font-h1:36px;
$font-h2:24px;
$font-h3:16px;
$font-face-small:14px;

html,body{
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
}

a:hover{
  text-decoration: none!important;
  color: $sky-blue;
}
.btn{
  border-radius: 2px;
}
.btn.btn-primary{
  background-color: $sky-blue;
  border-color: $sky-blue;
  color: $white;
  font-family: $font-face-medium;
  margin-right: 10px;
}
.btn.btn-primary:hover{
  background-color: $sky-blue-hover;
}
.btn.btn-secondary{
  background-color: $orange;
  border-color: $orange;
  color: $white;
  font-family: $font-face-medium;
}
.btn.btn-secondary:hover{
  background-color: $orangehover;
}
a{
  color: $sky-blue;
}
i.fas.fa-expand{
  margin-left: 8px;
}
li{
  list-style: none;
}

pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.428571429;
    word-break: break-all;
    word-wrap: break-word;
    color: #333333;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 2px;
}
.caret{
  border: solid$slate-gray;border-width: 0 2px 2px 0;display: inline-block;padding: 3px; transform: rotate(45deg); -webkit-transform: rotate(45deg);margin-left: 10px;margin-top: -4px;vertical-align: middle;
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 2px;
    clear: both;
    li{
      display: inline;
      a{
        position: relative;
        float: left;
        padding: 6px 12px;
        line-height: 1.428571429;
        text-decoration: none;
        color: #337ab7;
        background-color: $white;
        border: 1px solid $light-gray;
        margin-left: -1px;
      }
      span{
        position: relative;
        float: left;
        padding: 6px 12px;
        line-height: 1.428571429;
        text-decoration: none;
        color: #337ab7;
        background-color: $white;
        border: 1px solid #ddd;
        margin-left: -1px;
      }
    }
    li:first-child{
      a{
        margin-left: 0;
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
      }
      span{
        margin-left: 0;
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
      }
    }
    li:last-child{
      a{
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
      }
      span{
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
      }
    }
}


body{
  margin: 0;
  padding: 0;
  font-family: $font-face-regular;
  position: relative;
}
ul{
  text-decoration: none;
  list-style: none;
}

h1{
  font-size: $font-h1;
  line-height: 42px;
  font-family: $font-face-light;
  color: $slate-gray;
}
h2{
  font-size: $font-h2;
  line-height: 28px;
  color: $slate-gray;
}
h3{
  font-size: $font-h3;
  line-height: 19px;
  color: $slate-gray;
}
h4{
  font-size: $font-face-small;
  line-height: 16px;
  color: $slate-gray;
}
p{
  font-size: $font-face-small;
  line-height: 24px;
  color: $slate-gray;
}
/*@media(max-width: 767px){
  body .container {
    max-width: 100%;
    padding: 0 30px;
  }
}*/

.padd_00{padding: 0;}
a.specif_btn{
  border: 0px solid;border-radius: 2px;font-family: $font-face-medium; background-color:$sky-blue;display: table;color: $white;padding: 15px 25px;font-size: 12pt;letter-spacing: 0.5px;line-height: 19px; text-decoration: none;
  @media screen and (max-width: 767px){
    font-size: $font-h3;
    padding:15px 25px;
  }
}
a.specif_btn:hover{background-color: $sky-blue-hover;}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
}

header{
  margin-bottom: 50px;
  .navbar-expand-md{
    background-color: transparent;border-color:transparent;padding: 24px 15px;;
    @media(max-width: 767px){
      padding-top: 20px;
      padding-bottom: 20px;
      .navbar-collapse{
        position: absolute;top: 0;z-index:999;min-height:100vw;background:$white;
      }
      .nav-logo{
        padding-left: 15px; float:left;margin-top:30px;margin-right: 20px;
      }
    }
    /*.container-fluid{
      @media(max-width: 1140px){
        padding-right: 0;
      }
    }*/
    .navbar-brand{
      padding: 0;height: auto;
    }
    .navbar-toggler{
      .navbar-toggler-icon{
        background-image: url("/images/toggle.png");
        background-repeat: no-repeat;
        width: 26px;
        height: 22px;
      }
    }
    .navbar-toggler:focus{
      outline:none;
    }
    .navbar-nav{
      float: right;
      width: 100%;
      li{
        @media(max-width: 767px){
          padding:0px 15px;
        }
        a.nav-link{
          font-size: $font-face-small;color: $slate-gray;line-height: 16px;padding: 15px;font-family: $font-face-medium;
          @media(max-width: 991px){
            padding: 12px;
          }
            @media(max-width: 767px){
              font-size: 18px;
            }
          .caret{
            @media(max-width: 767px){
              display: none;
            }
          }
        }
        a.nav-link:focus{
          color: $slate-gray;
        }
        a.nav-link:hover{
          color: $slate-gray;
        }
      }
      .dropdown:hover>.dropdown-menu {
          display: block;
        }
      li.dropdown{
        .dropdown-toggle{
          padding-right: 0;
        }
        .dropdown-menu.sub-menu{
          left: 100%;
          top: 0;
          @media(max-width: 767px){
            left: 0;
          }
          li:hover{
            background: $light-gray;
            @media(max-width: 767px){
              background-color: #f8f9fa;
            }
          }
        }
        .dropdown-menu{
          padding: 15px 0px;
          min-width: 180px;
          box-shadow: none;
          border-radius: 2px;
          background-color: $slate-gray;
          position: absolute;
          left: 0px;
          margin: 0;
          border: 0px;
          border-radius:2px;
            @media(max-width: 767px){
              position:relative;display: block;background-color: $white;box-shadow: none;border: none;color: $black;left: 0;padding-top: 0;top: 0;
            }
            a{
              color:$white;opacity: 1;padding: 6px 25px;font-size: $font-face-small;line-height: 16px;display: block;font-family: $font-face-medium;
               @media(max-width: 767px){
                color:$black;
                font-weight: normal;
                padding: 10px 25px;
               }
            }
            a:active{
              background-color: transparent;
            }
            @media(min-width: 768px){
              a:hover{color:$white;background-color: transparent;}
            }
            li:hover{
              background: $light-gray;
              @media(max-width: 767px){
                background-color: transparent;
              }
            }
            li.dropdown{
              .caret{
                border: solid $white;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                position: absolute;
                right: 20px;
                top: 14px;
              }
            }
          }
        }
      i.fa-github{
        font-size: 26px;line-height: 45px;padding: 0 15px;color: $slate-gray;
        @media(max-width: 767px){
          margin-bottom: 35px;
          margin-top: 25px;
        }
      }
      li:last-child{
        ul.dropdown-menu{
          right: 0;left: auto;
        }
      }
      .active{
        a.nav-link, a.nav-link:hover, a.nav-link:focus{
          color: $slate-gray;background-color: transparent;opacity: 1;
        }
      }
      .open{
        a.nav-link, a.nav-link:hover, a.nav-link:focus{
          color: $slate-gray;background-color: transparent;opacity: 1;
        }
      }
    }
    #myNavbar{border-color: transparent;padding: 0;}
  }

  .nav-container{
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right:15px;

    @media screen and (min-width: 768px){
      max-width: 720px
    }

    @media screen and (min-width: 992px){
      max-width: 960px
    }

    @media screen and (min-width: 1200px){
      max-width: 1140px
    }

    @media screen and (max-width: 767px){
      padding-left:0px;
      max-width:100%;

      .navbar-brand{
        margin-top: 10px;
        margin-left: 30px;
      }
    }
  }
}

.tophead-banner{
  position: relative;

  .home-banner-image{
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -9;
    .left-banner{
      width: 50%;
      float: left;
      img{
        @media(min-width: 1400px){
          transform: scale(1.5);
          transform-origin: 0% 100%;
        }
        @media(max-width: 767px){
          max-width: 100%;
        }
      }
    }
    .right-banner{
      width: 50%;
      float: left;
      img{
        @media(min-width: 1400px){
          transform: scale(1.5);
          transform-origin: 100% 100%;
        }
        @media(max-width: 767px){
          max-width: 82%;
        }
      }
    }
  }
  .tophead-content{
    h2{
      color: $slate-gray;
      margin-bottom:0px;
      font-size: 36px;
      line-height: 42px;
      font-family: $font-face-light;
      @media(max-width: 575px){
        font-size: 18px;line-height: 25px;margin-top: 20px;
      }
    }
    p{
      font-size: $font-face-small;
      font-family: $font-face-medium;
      color:$sky-blue;
      margin-bottom: 48px;
       @media(max-width: 575px){
        margin-bottom: 70px;
      }
    }
    .buttons{
      margin: 35px auto 30px;
      text-align: center;
      display: inline-block;

      a{
        border: 0px solid;border-radius: 2px; background-color:$sky-blue;color: $white;padding: 20px 25px;font-size: $font-h3;letter-spacing: 0.5px;line-height: 19px; text-decoration: none;margin-left: 25px;width: 190px;display: block;float: left;font-family: $font-face-medium;
        @media(max-width: 767px){
          width: 190px;display: block;margin:0 auto;margin-bottom: 15px;float: none;display: inline-block;margin-left: 30px;
        }
        @media(max-width: 575px){
          margin:0;
        }
      }
      a:hover{
        background-color: $sky-blue-hover;
      }
      a.learn-btn{
        background-color: $orange;
      }
      a.learn-btn:hover{
        background-color: $orangehover;
      }
      .try_btn{
        @media(max-width: 575px){
          display: none;
        }
      }
    }
  }
}

.homepage-logos{
  .logo {
    width: auto;
    float: left;
    margin: 0 auto 15px;
    @media(max-width: 767px){
      width: 16%;
      margin-bottom: 20px;
      margin-top: 5px;
    }
    @media(max-width: 575px){
      width:33.33%;
    }
    img{
      margin: 0 auto;
      display: block;
    }
  }
}


#content-bg-color{
  background-color: $slate-gray;color:$white;padding: 75px 0 100px;
    @media(max-width: 575px){
      padding: 30px 0 50px;
    }
    .homepage_icons{
        svg{
          margin: 0 5px;
          @media(max-width: 767px){
            width: 70px;
            height: 72px;
          }
        }

        a.gray{
          .svg{
            path,polygon,rect{
              fill:$flash-white;
            }
          }
        }

        @media(max-width: 575px){
          width: 260px;
          margin: 0 auto;
        }
      }
  h2{
    margin-bottom: 30px;margin-top: 20px;color: $white;
    @media(max-width: 575px){
      font-size: 18px;line-height: 25px;padding: 0 20px;
    }
  }
  p{
    font-size: $font-face-small;line-height: 24px; width: 445px;margin: 0 auto;margin-bottom: 42.58px;color: $white;
    @media(max-width: 575px){
      width: 100%;
    }
  }
}

#demo-root-container {
  #playground-btn-block {
    text-align: center;
    padding: 30px;
  }
  a.playground-btn{
    max-width: 190px;
    height: 55px;
    padding: 0;
    margin: auto;
    border: 0px;
    border-radius: 3px;
    background-color: $sky-blue;
    color: $white;
    font-family: $font-face-medium;
    font-size: $font-h3;
    letter-spacing: 0.5px;
    line-height: 55px;
    text-align: center;
  }
  a.playground-btn:hover {
    background-color: $sky-blue-hover;
  }
  #demo-message {
    background-image: linear-gradient(#F9F9F9, #FFFFFF);
    text-align: center;
    padding: 40px;
    h2 {
      opacity: 85%;
      font-size: 22px;
    }
    h3 {
      opacity: 75%;
    }
  }
}


.raml-editor{
  padding: 0 50px;
  border-radius: 10px;
  position: relative;
  background-color: #fbfbfb;

  #container {
    border-width: 1px;
    border-color: #d3d5d9;
    border-style: solid;
    border-radius: 10px;
    width:59%;
    height:655px;
    overflow: hidden;
  }
  h2{
    color: $light-gray; font-family: 'Roboto-Regular';  font-size: $font-face-small;  font-weight: 500; line-height: 16px;margin:0;background: $lighter-gray;padding: 20px;border-top: 1px solid $light-gray;width: 100%;}
  .output-block{
    h4{
      background-color: $flash-white;color: $black;
    }
  }
  .editor-block{
    overflow: hidden;

    .indexPageEditorLineDecoration {
      background: #03d7ff;
      width: 5px!important;
      left: 0!important;
    }
    .monaco-editor{
      border-radius: 10px;
      .monaco-editor-hover{
        margin-left: 10%;
      }
      .overflow-guard {
        border-radius: 10px;
      }
      .selected-line-editor-decoration{
        background-color: $bluish;
        width: 7px !important;
        margin-left: 1px;
      }
      .editor-scrollable{
        .view-lines{
          .mtk26,.mtk23,.mtk21{
            font-family: 'RobotoMono-Regular';
            font-weight: 500;
          }
          .mtk8{
            color: #9EAAB7;
            font-family: 'RobotoMono-Regular';
            letter-spacing: 1px;
            font-weight: 500;
          }
        }
      }
      .view-overlays{
        .current-line{
          background-color:#DBE8FD;
        }
      }
    }
    .line-numbers{
      color: #9EAAB7;
    }

    /* Editor code tips */
    #editor-tips-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;

      .tip-wrapper {
        width: 100%;
        position: absolute;
        z-index: 999;
        left: 5px;
        display: table;
      }
      .tip-background {
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: #808080;
        left: 0;
        top: 0;
        opacity: 0.1;
      }
      .tip-wrapper:hover .tip-background {
        background-color: #03d7ff;
      }
      .tip-text {
        display: table;
        height: 100%;
        width: 40%;
        padding-left: 10px;
        padding-right: 10px;
        position: absolute;
        right: 0;
        a {
          text-decoration: underline;
        }
        p {
          display: table-cell;
          vertical-align: middle;
        }
      }
      /* END Editor code tips */
    }
  }
}


.raml-blocks{
  .txt-block{
    margin-top: 100px;
    @media(max-width: 767px){
      margin-top: 50px;
    }
    @media(max-width: 575px){
      margin-top: 40px;
    }
  }
  .txt-left{
    padding-right: 7%;
    @media(max-width: 575px){
      padding-right: 15px;
      margin-bottom: 40px;
    }
  }
  .txt-right{
    padding-left: 7%;
    @media(max-width: 575px){
      padding-left: 15px;
    }
  }
  .img-left{
    text-align: left;
  }
  .img-right{
    text-align: right;
  }
  h3{
    letter-spacing: 0.5px;font-family: $font-face-bold;color: $slate-gray;font-size: $font-h2;line-height: 28px;margin-bottom: 20px;margin-top: 25px;

    @media(max-width: 767px){
      font-size: $font-h3;
      line-height: 19px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }
  img{
    height: 100%;
    @media(max-width: 991px){
      width: 75%;
    }
    @media(max-width: 767px){
      width: 85%;
    }
    @media(max-width: 575px){
      display: none;
    }
  }
  .row-bg-color{
    background-color:$lighter-gray;
    padding: 105px 0;
    margin-top: 105px;
    img{
      margin-top: 0;
    }
    @media(max-width: 767px){
      padding:50px 0;
      margin-top:50px;
    }
    @media(max-width: 575px){
      padding:40px 0;
      margin-top:40px;
    }
  }
  .more-btn{
    border:2px solid;
    padding: 10px 35px 10px 20px;
    margin-top: 30px;
    display: block;
    width: 155px;
    text-align: center;
    font-family: $font-face-medium;
    border-radius: 2px;
    color: $slate-gray;
    @media(max-width: 575px){
      margin-top: 20px;
    }
  }

  .more-btn:before{
    content: "\f105";font-weight: bold;font-family: 'Font Awesome\ 5 Free';font-style: normal;text-decoration: inherit;font-size: 18px;position: absolute;top: 9px;right: 20px;
  }
  p{
    color: $light-gray;
  }
  a{
    font-size: $font-face-small;line-height: 22px;position: relative;
  }
  .bdr{
    border:0px solid;padding: 30px;border-radius: 2px;margin-top: 42px;background-color: $lighter-gray;
      @media(max-width: 767px){
        margin-top: 50px;
      }
    h1{
      margin-top: 0;
      color: $slate-gray;
      font-size: $font-h2;
      line-height: 28px;
      font-family: $font-face-bold;
    }
    p{
      width: 100%;margin-bottom: 20px;font-family: $font-face-light;
    }
  }
  a.browse-projects-btn{
    font-size: $font-h3;line-height: 19px;letter-spacing: 0.5px;border:0px solid;border-radius: 2px;background-color: $slate-gray;color: $white;display: block;padding: 20px;text-align: center;font-family: $font-face-medium;
    margin: 17% 0;
    @media(max-width: 767px){
      margin: 50% 0;
    }
    @media(max-width: 575px){
      margin: 0;
    }
  }
  .block-quote{
    margin: 80px auto 137px;
    @media(max-width: 767px){
      margin: 80px auto 95px;
    }
    @media(max-width: 575px){
      margin: 0;padding-bottom: 30px;
    }
    .col-lg-6.col-md-8{
      margin: 0 auto;
    }
    h1{
      font-size: $font-h2;line-height: 36px;color: $slate-gray;position: relative;text-align: center;margin-bottom: 20px;margin-top: 15px;
        @media(max-width: 575px){
          font-size: $font-face-small;line-height: 28px;padding: 50px 37px 0;margin: 0;
        }
    }
    p{
      text-align: center;
      color: $light-gray;
      font-size: $font-h3;
      margin-bottom: 0;
    }
    span{
      display: block;
      text-align: center;
      color: $light-gray;
      font-size: $font-h3;
      font-family: $font-face-light;
    }
    h1:before {
      content: "\f10d";font-weight: bold;font-family: 'Font Awesome\ 5 Free';font-style: normal;text-decoration: inherit;color: #B3B7BF;font-size: 18px;position: absolute;top: 0px;left: -30px;
         @media(max-width: 575px){
          top: 50px;left: 15px;
         }
    }
    h1:after {
      content: "\f10e";font-weight: bold;font-family: 'Font Awesome\ 5 Free';font-style: normal;text-decoration: inherit;color: #B3B7BF;font-size: 18px;position: absolute;bottom: -5px;right: 0px;
       @media(max-width: 575px){
          bottom: 0px;right: 15px;
         }
    }
  }
}

.footer-sec{
  padding-top: 10px;
  padding-bottom: 10px;
  @media(max-width: 767px){
    .footer-menu{
      ul{
        clear: both;display: block;padding-left:0;  padding-top: 20px;
      }
    }
  }
  .navbar-brand{
    padding: 0;
    height: auto;
    @media (max-width: 991px){
      display: block;
    }
  }
  .col-md-5.col-sm-12{
    @media(max-width: 991px){
      padding-right: 0;
    }
    .right-align{
      @media(max-width: 991px){
        padding-top: 50px;
      }
      @media(max-width: 767px){
        padding-top: 10px;
      }
    }
  }
  ul{
    display: inline-block;
    padding: 0;
    @media(max-width: 991px){
      clear: both;
      padding:0;
      padding-top: 20px;
    }
    @media(max-width: 767px){
      margin-bottom: 0;
      padding-top:10px;
    }
    li{
      display: inline-block;
      @media(max-width: 375px){
        display: block;
      }
      a{
        font-size: $font-face-small;line-height: 16px;padding: 10px 9px;font-weight: 500;color: $slate-gray;font-family: $font-face-medium;
        @media(max-width: 767px){
          display: block;
        }
        @media(max-width: 375px){
          padding:10px 0;
        }
        button#ot-sdk-btn {
          background: none;
          border: 0px;
          outline: 0;
          font-size: 12px;
          color: #8a8a8a;
          font-weight: normal;
          display: block;
          padding: 10px 15px;
          text-decoration: none;
          cursor: pointer;
        }
        button#ot-sdk-btn:hover {
        color: #337ab7;
        }
      }
      button#ot-sdk-btn {
        font-size: $font-face-small;line-height: 16px;padding: 10px 9px;font-weight: 500;color: $slate-gray;font-family: $font-face-medium;
        border: 0px;
        outline: 0px;
        background: none;
        cursor: pointer;
        @media(max-width: 767px){
          display: block;
        }
        @media(max-width: 375px){
          padding:10px 0;
        }
      }
    }
    li:nth-child(1) a{
        padding-left: 0;
      }
  }
  img{
    width: 65px;margin-right: 20px;
      @media(max-width: 767px){
        margin-top: 15px;
      }
  }
  .col-md-9{
    margin-top: 10px;
  }
  .col-md-3{
    margin-top: 10px;
  }
  a.top_btn{
    margin-top: 10px;margin-bottom: 30px;color: $light-gray;position: relative;padding-right: 38px;float: right;clear: both;display: table;font-size: $font-face-small;
    @media(max-width: 991px){
      float: none;
    }
  }
  a.top_btn:after {
    content: "\f106";font-family: 'Font Awesome\ 5 Free';font-style: normal;font-weight: bold;text-decoration: inherit;color: $light-gray;font-size: 18px;position: absolute;top: -5px;right: 15px;font-size: 20px;
  }
  .social-icons{
    margin-top: 5px;
    .fab{
      font-size: $font-h2;margin-right: 15px;color: $slate-gray;
    }

    img{
      width: 24px;
    }
    @media(max-width: 991px){
      margin-top: 20px;margin-bottom: 0px;
    }
    @media(max-width: 767px){
      margin-top: 0px;
    }
  }
  .right-align{
    float: right;
    @media(max-width: 991px){
      float: none;margin-bottom: 20px;
    }
    @media(max-width: 767px){
      padding-left:0;
      margin-bottom: 10px;
    }
  }
}



/*developers-sec*/
#developer_top{
    overflow: hidden;
  .developers-sec{

    @media(max-width:991px){
      padding-bottom:30px;
    }
    @media(max-width: 767px){
      margin-top: 0;
    }
    h1{
      color: $slate-gray;
      margin-bottom: 0px;
    }

    img{
      margin-top: 25px;
      @media screen and (max-width: 991px) and (min-width: 576px) {
        position: absolute;
        bottom: 0;
        transform: scale(1.25);
      }
    }
    p{
      margin: 30px 0 30px;
    }
  }
}

#expl-bg-color{
  background-color: $slate-gray;color: $white;padding: 73px 0 65px;
  @media(max-width: 767px){
    padding: 50px 0;
  }
  img{
    width: 165px;margin: 0 auto;display: block; margin-bottom: 36px;
  }
  h3{
    color: $white;
  }

  @media(max-width: 575px){
    img{
      margin-bottom: 12px;
    }

    h3{
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin:0 auto 30px auto;
    }
  }
}

.container.desc-block{
  padding-top: 100px;
  padding-bottom: 100px;
  @media(max-width: 767px){
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .txt-right{
    @media(min-width: 991px){
      float: right;
    }
  }
  .img-left{
    @media(min-width: 991px){
      float: left;
    }
  }
  .main-img img{
    margin-top:20px;

    @media(max-width: 767px){
      margin-top: 0px;
    }
  }
  .test-api{
    .main-img img{
      margin-top:64px;

      @media(max-width: 991px){
        margin-top: 0px;
      }
    }
  }

  .share-support{
    .main-img img{
      margin-top:77px;

      @media(max-width: 991px){
        margin-top: 0px;
      }
    }
  }

  .icon{
    width: 52px;
  }
  h3{
    color: $slate-gray;
    margin-top: 20px;
  }
  p{
    margin: 20px 0;
    font-family: $font-face-light;
  }
  a{
    color: $slate-gray;
    margin-bottom: 20px;
    display: block;
    font-size: $font-face-small;
    text-decoration: underline;
  }
}

#build-bg-color{
  background-color: $lighter-gray;
  .row{
    display: block;
  }
  .desc-block{
    .main-img img{
      margin-top: 100px;
      @media(max-width: 991px){
        margin-top: 0px;
      }
    }
  }
}

#document-bg-color{
  background-color: $lighter-gray;
  .row{
    display: block;
  }
  .desc-block{
    .main-img img{
      margin-top: 100px;
      @media(max-width: 991px){
        margin-top: 0px;
      }
    }
  }
}


.container.do_more_less{
  padding-bottom: 125px;
    @media(max-width: 767px){
      padding-bottom: 85px;
      margin-top: 50px;
    }
    @media(max-width: 575px){
      margin-top:0;
      padding-bottom: 30px;
    }
  h2{
    margin: 0;
    color: $slate-gray;
  }
  p{
    margin:20px 0 15px;
  }
  .center-box{
    margin: 0 auto;
  }
  .box-color{
    background-color: $lighter-gray;
    padding: 30px;
    a.btn_black{
      background-color: $slate-gray;
    }
  }
}

#enterprise_top{
  overflow: hidden;
  .container.enterprise{
    @media(max-width: 767px){
      padding-top: 30px;
      padding-bottom: 30px;
    }
    @media (max-width: 575px){
      padding-top:0px;
    }
    a.specif_btn{
      @media(max-width: 575px){
        margin-bottom: 20px;
      }
    }
    h1{
      color: $slate-gray;
      margin-bottom: 0;
    }
    h3{
      font-size: $font-face-small;
      line-height: 24px;
      color: $slate-gray;
      margin: 30px 0;
    }
  }
}



.tutorial-page{
  .region-sidebar{
    .raml-hundred{
      .tutorial_sidebar{
        margin: 0 20px 0 0;
      }
    }
    .sidebar-menu{
      h2{
        font-size: 16pt;
      }
      .lastsidemenu{
        color: $slate-gray;
      }
      .ramlapilinks {
        margin-top: 10px;
        @media(max-width: 767px){
          margin-top: 0;
        }
        @media(max-width: 630px){
          margin-top: 10px;
        }
      }
      @media(max-width: 630px){
        .ramlapilinks {
          display: block;
          float: none;
          margin-bottom: 10px;
        }
        .tutorial_sidebar {
          display: block;
          margin: 0px;
          float: none;
        }
        .lastsidemenu {
          display: block;
          float: none;
          margin-top: 15px;
        }
      }
    }
  }
}

.document-your-api{
  img{
    @media(max-width: 575px){
      float: none !important;
      margin-bottom: 15px;
    }
  }
}

.page-content.vendor-support-page{
  .region-sidebar{
    .sidebar-menu.box-color{
      .tutorial_sidebar{
        @media(max-width: 767px){
          margin: 0;
        }
      }
    }
  }
  .vendor-text{
    color: $slate-gray;
  }
}
.page-content {
    .article-content{
      @media(min-width: 1400px){
        min-height: -o-calc(100vh - 387px);
        min-height: -webkit-calc(100vh - 387px);
        min-height: -moz-calc(100vh - 387px);
      }
    }
    .page-header {
      padding-bottom: 10px;
      margin: 13px 0 26px;
      font-size: 32px;
      color: $slate-gray;
      @media(max-width: 767px){
        margin: 30px 0;
      }
    }
    hr.d-sm-block.d-md-none{
      @media(max-width: 767px){
        margin: 0 15px!important;
      }
    }
    pre {
      padding: 0.6em 0.8em;
      font-size: 0.9em;
      line-height: 1.3;
      text-align: left;
    }
    a{
      color: $sky-blue;
    }

    ul{
      list-style:initial;
      li{
        list-style: initial;
        color: $slate-gray;
      }
    }
    .sidebar-menu{
      h2{
        margin-bottom: .8rem;
        font-family: $font-face-medium;
      }
      ul{
        margin-bottom: 1.5rem;
      }
      h3{
        @media(max-width: 767px){
          display: inline-block;
          float: right;
          margin: 0;
        }
      @media(max-width: 575px){
        display:block;
        float:none;
        margin-top: 20px;
        }
      }
      a.dropdown-toggle {
        color: $slate-gray;
      }
      .side_bar{
        @media screen and (max-width: 767px){
          float:left;
          width: 75%;
        }

        @media screen and (max-width: 575px){
          float:none;
        }
      }
    }
    .about-content-sec{
      @media(max-width: 991px){
        padding: 0 55px;
      }
      @media(max-width: 767px){
        padding: 0 15px;
      }
    }
    .region-sidebar{
      border:0px;border-radius: 2px;background-color: $flash-white;padding: 30px 35px;
      @media(max-width: 1199px){
        padding: 15px;
      }
        @media(max-width: 767px){
          background-color: transparent;
          padding: 0px 0px 10px;
        }
      ul{
        padding: 0;
        list-style: none!important;
        li{
          list-style: none!important;
          @media(max-width: 767px){
            display: inline-block;
            padding-right: 20px;
            width: auto;
          }
          @media(max-width: 575px){
            display:block;
          }
          a{
            font-size: $font-face-small; line-height:28px;color: $slate-gray;font-family: $font-face-medium;opacity: 0.4;
          }
          a.active{color: $slate-gray;opacity: 1;}
        }
      }
      .ramlapilinks{
        @media(max-width: 767px){
          display: inline-block;
          float: right;
          margin-top: 2px;
        }
        @media(max-width: 575px){
          margin-bottom: 10px;
          display:block;
          float:none;
        }
        a.specif_btn{
          color: $white;
        }
      }
      .lastsidemenu{
        @media(max-width: 767px){
          display: inline-block;
          float: left;
          margin-top: 15px
        }
        @media(max-width: 575px){
            margin: 0;
            display:block;
            float:none;
          }
      }
      .tutorial_sidebar{
        @media(max-width: 767px){
            display: inline-block;
            margin: 0 20px;
            float: left;
          }
          @media(max-width: 575px){
            margin: 0;
            display:block;
            float:none;
          }
        .dropdown-menu{
          padding: 10px;
          width: 250px;
        }
        .dropdown-toggle {
          border: 2px solid;
          border-radius: 2px;
          padding: 5px 7px;
          margin-top: 10px;
          margin-bottom: 10px;
          display: table;
          font-size: 11pt;
        }
      }
    }
    .vendor-support{
      h2{
        margin-bottom: 0;
        margin-top: 15px;
      }
      .col-lg-12{
        padding: 0;
        img{
          margin: 25px 0 15px;
        }
        .vendor-text{
          a{
           display: block;
           margin-top: 10px;
          }
        }
      }
    }

    .books-resources{
      h3{
        margin-top: 20px;
      }
      h2{
        a{
          color: $slate-gray;
        }
      }
      sdivong{
        color: $slate-gray;
        font-family: 'Roboto-Bold';
      }
      span{
        display: block;
        color: $slate-gray;
        font-family: 'Roboto-Light';
      }
      .book_pic {
        img{
          margin-top: 10px;
          width: 180px;
          @media(max-width: 575px){
            width: auto;
          }
         }
      }
    }
  }

.projectdiv {
  padding: 45px 15px;
  a{
    color: $sky-blue;
  }
    input#search {
      width: 350px;
      height: 42px;
      border: 2px solid $light-gray;
      padding: 0 15px;
      border-radius: 2px;
      color: $light-gray;
      position: absolute;
      right: 0;
      top: 48px;
      @media(max-width: 991px){
        width:300px;
        position: relative;
        left: 0;
        top: 0;
      }
    }
    form#submit_form {
      float: right;
      @media(max-width: 991px){
        float: left;
      }
    }
  .projecttab{
    margin-top: 15px;
    ul.proj_cat{
      list-style: none;
      height: 40px;
      padding: 0;
      margin-bottom: 48px;
      li{
        float: left;
        margin-bottom: 15px;
      }
      a {
        font-size: $font-face-small;
        line-height: 16px;
        color: $slate-gray;
        padding: 10px 16px;
        text-decoration: none;
      }
      a.active {
        color: $white;
        background-color: $slate-gray;
        border-radius: 2px;
        text-decoration: none;
      }
    }
  }
  ul.pagination{
    li{
      a{
        color: $slate-gray;
      }
    }
  }
  .result{
    .projectblock:hover{
      border: 2px solid $light-gray;
    }
    .projectblock{
      border-radius: 2px;
      padding: 30px;
      margin-bottom: 40px;
      border: 2px solid $flash-white;
      width: 100%;
      h3{
        font-size: $font-h2;
        line-height: 28px;
        color: $slate-gray;
        margin-bottom: 20px;
      }
      .projtag {
        border: 1px solid #DBE8FD;
        border-radius: 2px;
        padding: 3px 8px;
        display: inline-block;
        margin-right: 15px;
        margin-bottom: 20px;
        color: $slate-gray;
        background-color: #DBE8FD;
      }
      .projlink{
        display: flex;
        @media(max-width: 1199px){
          display: block;
        }
        a{
          margin-bottom: 15px;
        }
        .view-project{
          margin-right: 25px;

          @media (max-width: 767px) and (min-width: 576px){
            margin-right: 15px;
          }
        }
      }
    }
  }
}

.projectlinks{
  border-radius: 2px;
  padding: 30px;
  margin-bottom: 46px;
  .links{
    text-align: center;
    display: block;
  }
  a{
    color: $slate-gray;
    font-size: $font-face-small;
    line-height: 16px;
    display: inline-block;
    padding: 0 7.5px;
  }
  .projectlinks-bg-color{
    background-color: $lighter-gray;
    box-shadow: 0 0 30px 0 $lighter-gray;
    margin: 0 auto;
    padding:30px;
  }
}

.ramlenter {
    background: $slate-gray;
    padding: 29px 0 60px;
    font-size: $font-h3;
    .thumbnail{
      img{
        margin: 20px auto;
        display: block;
      }
      p{
        color: $white;
      }
    }
}

.multiple-teams {
    padding: 82px 0 77px;
    font-size: $font-h3;
    color: #8a8a8a;
    @media(max-width: 767px){
      padding: 50px 0;
    }
}

.enterprise-faster {
    padding: 78px 0 81px;
    background: $lighter-gray;
    color: #8a8a8a;
    font-size: $font-h3;
    @media(max-width: 767px){
      padding: 50px 0;
    }
}

.open-source-com {
    background: $white;
    padding: 62px 0 80px;
    font-size: $font-h3;
    @media(max-width: 767px){
      padding: 50px 0;
    }
}

.eliminate-support {
    background: $lighter-gray;
    color: #8a8a8a;
    padding: 61px 0 82px;
    font-size: $font-h3;
    @media(max-width: 767px){
      padding: 50px 0;
    }
}

.do-more-with-less {
    background: $white;
    padding: 78px 0 55px;
    font-size: $font-h3;
    margin-bottom: 10px;
    .do-more-content {
      margin: 0 auto;
    .raml-links{
      background: $white;
      padding: 15px;
      border-radius: 2px;
      font-weight: 600;
    }
  }
  .ramllink-wrapper{
    a{
      margin: 0 auto;
    }
  }
}

.member {
    margin-top: 45px;
    @media(max-width: 575px){
      text-align: center;
    }
  .member-photo{
    img{
      margin: 0 18px 0 0;
      float: left;
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      background-clip: padding-box;
      border: 4px solid #0b9be6;
      height: 100px;
      width: 100px;
      background: #f2f2f2;
      @media(max-width: 1199px){
        float: none;
        width: 170px;
        height: 170px;
        margin: 0 auto;
        display: block;
      }
      @media(max-width: 575px){
        width: 200px;
        height: 200px;
      }
    }
  }
  .member-info {
    max-width: 50%;
    float: left;
    @media(max-width: 1199px){
      max-width: 100%;
      float: none;
      text-align: center;
    }
    p {
      padding: 0;
      margin: 0;
      line-height: 16px;
    }
    h3 {
      margin-top: 3px;
      margin-bottom: 0;
      font-weight: bolder;
      @media(max-width: 875px){
        margin-top: 15px;
      }
    }
    a {
      display: inline-block;
      height: 25px;
      width: 25px;
      margin-top: 3px;
    }
    a.twitter {
      background-image: url(/images/twitter.svg);
      background-size: 100%;
      margin-left: 15px;
    }
    a.linkedin {
      background-image: url(/images/linkedin.svg);
      background-size: 100%;
    }
  }
}
.contributors {
  .contributors-list {
    a {
      display: inline-block;
      height: 15px;
      width: 15px;
    }
    a.twitter {
      background-image: url(/images/twitter.svg);
      background-size: 100%;
      margin-left: 5px;
    }
    a.linkedin {
      background-image: url(/images/linkedin.svg);
      background-size: 100%;
    }
  }
}
.build-your-api{
  .btn.btn-primary{
    margin-right: 15px;
  }
}

.page-content.design-your-api .article-content, .page-content.build-your-api .article-content, .page-content.test-your-api .article-content, .page-content.build-your-api .article-content, .page-content.document-your-api .article-content, .page-content.support-your-api .article-content{
  @media(max-width: 767px){
    margin-top: 20px;
  }
}

.dropdown-toggle:after {
  display: none;
}

.monaco-editor-overlaymessage {
  display: none;
}

.vs-dark .monaco-scrollable-element>.scrollbar>.slider {
  background: #9eaab7!important;
}
.monaco-scrollable-element>.scrollbar>.slider:hover {
  background: #9eaab7!important;
}

a.ramloldlink {
  font-size: 12px;
  color: #fff;

  &:hover {
    text-decoration: none;
    color: #ffffff;
  }
}

#ot-sdk-btn {
    background: none;
    border: 0px;
        font-size: 12px;
    color: #8a8a8a;
    font-weight: normal;
    display: block;
    padding: 10px 15px;
    text-decoration: none;
}
#ot-sdk-btn:focus {
    outline: 0;
}

#ot-sdk-btn:hover {
    color: #337ab7;
}

.cpra-link {
  &:after{
    content: '';
    background-image: url('/images/icon-cpra.svg');
    width: 26px;
    height: 16px;
    position: absolute;
    background-size: 26px 16px;
    background-repeat:no-repeat;
    margin-left: 8px;
    @media screen and (min-width: 768px){
      margin-top: 4px;
    }
  }
}